<template>
  <div v-cloak :class="`${!isDesktop ? 'mobile' : '' }`" :data-theme="theme" :style="`--color:#${themeColor}`">
    <Mobile v-if="!isDesktop" />

    <div v-if="isDesktop && (!user || (user.group !== 777 && user.group !== 0))" v-show="showVideoAd" class="videoroll">
      <div class="close">
        <span v-if="videoAdCounter">Можно закрыть через {{ videoAdCounter }}</span>
        <i v-else @click="showVideoAd = !showVideoAd" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2003632-4"></div>
      </div>
    </div>

    <!-- <div
      v-if="!tabActive"
      class="on-blur"
      style="display:block;position:fixed;top:0px;left:0px;width:100%;height:100%;background-color:rgba(0,0,0,0.55);z-index:10;"
    /> -->

    <nav v-if="isDesktop" class="menu">
      <div class="left">
        <!-- <nuxt-link to="/dobro" :class="{ 'active': $route.name === 'dobro' }">
          <img :src="`/static_files/icons/crown${theme === 'dark' ? '-dark' : ''}.svg`" alt="">
          Dobro
        </nuxt-link> -->
        <nuxt-link to="/catalog" :class="{ 'active': $route.name === 'catalog' }">
          <img :src="`/static_files/icons/paper${theme === 'dark' ? '-dark' : ''}.svg`" alt="">
          Каталог кино
        </nuxt-link>
        <nuxt-link :to="`/catalog?year=${new Date().getFullYear()}&sort=date`" :class="{ 'active': !!$route.query.year && $route.query.year == new Date().getFullYear() }">
          <img :src="`/static_files/icons/new.svg?2`" alt="">
          Новинки {{ new Date().getFullYear() }}
        </nuxt-link>
        <nuxt-link to="/popular" :class="{ 'active': $route.name === 'popular' }">
          <img :src="`/static_files/icons/star${theme === 'dark' ? '-dark' : ''}.svg`" alt="">
          Топ 100
        </nuxt-link>
        <nuxt-link to="/chat" :class="{ 'active': $route.name === 'chat' }">
          <img :src="`/static_files/icons/chat${theme === 'dark' ? '-dark' : ''}.svg`" alt="">
          Общий чат
        </nuxt-link>
      </div>
      <div class="center">
        <nuxt-link to="/" class="logo" :class="themeColor">TURK KINO</nuxt-link>
      </div>
      <div v-if="user" class="right">
        <div @click="showUserMenu = !showUserMenu" class="auth">
          <div class="avatar">
            <img :src="user.photo" :alt="user.name">
          </div>
          <strong>{{ user.name }}</strong>
        </div>
      </div>
      <div v-else class="right">
        <nuxt-link :to="`/signin?ref=${encodeURI($route.fullPath)}`" class="login">
          Войти
        </nuxt-link>
      </div>
    </nav>
    <nav v-else class="menu">
      <nuxt-link to="/chat" tag="div" :class="{ 'active': $route.name === 'chat' }">
        <i class="icon-chat" />
        <strong>Общий чат</strong>
      </nuxt-link>
      <div @click="showLeftSide" :class="{ 'active': rooms }">
        <i v-if="!rooms" class="icon-rooms" />
        <i v-else class="icon-close" />
        <strong>Комнаты</strong>
      </div>
      <nuxt-link to="/" tag="div" :class="{ 'active': $route.name === 'index' }">
        <i class="icon-home" />
        <strong>Главная</strong>
      </nuxt-link>
      <nuxt-link :to="`/catalog?year=${new Date().getFullYear()}&sort=date`" tag="div" :class="{ 'active': !!$route.query.year && $route.query.year == new Date().getFullYear() }">
        <i class="icon-catalog" />
        <strong>Каталог</strong>
      </nuxt-link>
      <div v-if="user">
        <div @click="showUserMenu = !showUserMenu" class="auth">
          <div class="avatar">
            <img :src="user.photo" :alt="user.name">
          </div>
          <strong>Профиль</strong>
        </div>
        <input
          ref="avatarUpload"
          type="file"
          style="display:none;"
          accept="image/gif, image/png, image/jpeg, image/jpg"
          @change="uploadFile($event, 'avatar')"
        >
      </div>
      <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="div">
        <i class="icon-profile" />
        <strong>Войти</strong>
      </nuxt-link>
    </nav>

    <div v-if="showUserMenu" class="user-menu">
      <div>
        <strong>{{ user.name }}</strong>
        <i @click="showEditName = !showEditName" class="edit" />
      </div>
      <div v-if="showEditName">
        <input v-model="newName" type="text" min="3" max="30" placeholder="Новое имя">
        <div>
          <button @click="changeName" class="nickname">
            Изменить
          </button>
          <button @click="showEditName = !showEditName" class="nickname">
            Отменить
          </button>
        </div>
        <p style="font-size:1.2rem;">Первая смена бесплатно, 2-я и последующие 10руб.</p>
      </div>
      <input
        ref="avatarUpload"
        type="file"
        style="display:none;"
        accept="image/gif, image/png, image/jpeg, image/jpg"
        @change="uploadFile($event, 'avatar')"
      >
      <div @click="avatarUploadTrigger" class="avatar">
        <img :src="user.photo" :alt="user.name">
        <span>Изменить</span>
      </div>
      <div style="text-align:right;">
        <nuxt-link to="/dobro" tag="span">Баланс: <b style="color:red;">{{ user.balance }}</b></nuxt-link>
      </div>
      <div>
        <nuxt-link to="/history" tag="span">История просмотров</nuxt-link>
      </div>
      <div>
        <nuxt-link to="/favorites" tag="span">Избранное</nuxt-link>
      </div>
      <div>
        <nuxt-link to="/dobro" tag="span">Подписка</nuxt-link>
      </div>
      <div>
        <span @click="logout">Выйти</span>
      </div>

      <div v-if="!isDesktop" style="margin:40px 10px 0px;text-align:center;">
        <a href="https://turk-kino.ru/static_files/app/turkkino_android_v1.apk" target="_blank">
          <img src="/static_files/icons/google.svg" alt="TurkKino на Google Play">
        </a>
      </div>

      <div v-if="!isDesktop" class="links">
        <a href="mailto:@support@turk-kino.ru" target="_blank">
          support@turk-kino.ru
        </a>
        <a href="https://vk.com/turkkinoru" target="_blank">
          ВКонтакте
        </a>
        <a href="https://t.me/turkkinoru" target="_blank">
          Телеграм
        </a>
      </div>

      <div style="margin-top:40px;text-align:center;">
        <i @click="showUserMenu = !showUserMenu" class="close" />
      </div>
    </div>

    <TopFilms v-if="$route.name !== 'smotrim-id'" />

    <div class="main-wrapper" :class="{'inroom': inRoom}">
      <div v-if="!isDesktop && rooms" class="left-side">
        <div class="rooms-add">
          <Search :type="`rooms`" />
        </div>
        <div class="rooms-list">
          <strong>Активные комнаты</strong>
          <div v-if="rooms">
            <div style="display:grid;grid-template-columns:repeat(2,1fr);">
              <span v-if="rooms.list.length" style="display:inline-block;font-size:1.2rem;">
                Всего {{ rooms.count }}
                <template v-if="(rooms.count - rooms.list.length)">
                  | Приватные {{ rooms.count - rooms.list.length }}
                </template>
              </span>
              <span v-else>Не найдены</span>
              <span v-if="user" @click="myRooms" style="text-align:right;color:rgba(250, 162, 0, 1);cursor:pointer;">
                {{ !showMyRooms ? 'Мои комнаты' : 'Все комнаты' }}
              </span>
            </div>

            <div class="items">
              <ul v-if="rooms.list.length" style="height:100%;">
                <nuxt-link
                  v-for="item in rooms.list.slice(0, 20 * roomsI)"
                  :key="`room-${item._id}`"
                  :to="!item.hidden || (user && user.group === 0) ? `/smotrim/${item._id}` : ''"
                  tag="li"
                  :class="{
                    'hidden': item.hidden,
                    'active': $route.params.id === item._id,
                    'vmeste': item.vmeste,
                    'common': !item.owner,
                    'self': item.owner && user && user.id === item.owner.id
                  }"
                >
                  <div class="poster">
                    <img :data-src="item.film.material_data.poster" src="/static_files/no-poster.png" :alt="item.film.title_ru" class="lazy lazyload" />
                    <i v-if="item.hidden" class="icon-private" />
                  </div>
                  <div class="title">
                    <strong>{{ item.film.title_ru }}</strong>
                    <span class="en">{{ item.film.title_en }}</span>
                    <span>
                      <u v-if="item.vmeste">Сеанс в 21:00</u>
                      <u v-else-if="item.hidden">Приватная</u>
                      <u v-else-if="user && item.owner && item.owner.id === user.id">Ваша комната</u>
                      <u v-else>{{ item.owner ? item.owner.name : 'Общая комната' }}</u>
                      <template v-if="item.viewers"><b></b>Участники: {{ item.viewers }}</template>
                    </span>
                  </div>
                </nuxt-link>
                <li v-if="rooms.list.length > 20 && (20 * roomsI) < rooms.list.length">
                  <div></div>
                  <div @click="++roomsI" class="show-more">
                    <button>
                      <span>Показать еще</span>
                      <i class="arrow" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <i class="loader" /> Список загружается...
          </div>
        </div>
      </div>

      <div class="content-scroll">
        <div v-if="!user || (user.group !== 777 && user.group !== 0)" style="margin: 20px 0px;">
          <div id="yandex_rtb_R-A-2003632-3" class="rsya-block"></div>
          <!-- <div v-show="isDesktop && !inRoom && $route.name !== 'catalog-id'" id="yandex_rtb_R-A-2003632-3" class="rsya-block"></div>
          <div v-show="!isDesktop && !inRoom" id="yandex_rtb_R-A-2003632-2" class="rsya-block"></div> -->
          <nuxt-link to="/dobro" tag="div" class="close-ad">
            Убрать рекламу
          </nuxt-link>
        </div>

        <nuxt />
      </div>
    </div>

    <footer v-if="isDesktop">
      <div class="links">
        <a href="mailto:@support@turk-kino.ru" target="_blank">
          support@turk-kino.ru
        </a>
        <a href="https://vk.com/turkkinoru" target="_blank">
          ВКонтакте
        </a>
        <a href="https://t.me/turkkinoru" target="_blank">
          Телеграм
        </a>
      </div>
    </footer>

    <div class="picker">
      <!-- <i
        @click="showPicker = !showPicker"
      /> -->
      <div
        v-show="showPicker"
        class="picker-menu"
      >
        <!-- <div class="lang">
          <span @click="themeColor = 'e00a1e'" :style="`background-color:#e00a1e`">
            <b v-if="themeColor === 'e00a1e'">&#10003;</b>
          </span>
          <span @click="themeColor = 'faa200'" :style="`background-color:#faa200`">
            <b v-if="themeColor === 'faa200'">&#10003;</b>
          </span>
        </div> -->
        <div class="theme">
          <span @click="switchTheme('light')" class="light">
            <b v-if="theme === 'light'">&#10003;</b>
          </span>
          <span @click="switchTheme('dark')" class="dark">
            <b v-if="theme === 'dark'">&#10003;</b>
          </span>
        </div>
      </div>
    </div>

    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
// import LazyHydrate from 'vue-lazy-hydration'
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue'),
    TopFilms: () => import('~/components/TopFilms.vue'),
    Search: () => import('~/components/Search.vue'),
    // Menu: () => import('~/components/Menu.vue')
  },
  watch: {
    changeRoute() {
      this.showUserMenu = false
      if (!this.isDesktop) this.rooms = null
    
      // document.querySelector('.content-scroll').removeEventListener('scroll', this.lazyLoadImgs)

      // this.$nextTick(() => {
      //   const cScroll = document.querySelector('.content-scroll')
      //   if (cScroll) {
      //     cScroll.style.overflow = ''
      //     cScroll.addEventListener('scroll', this.lazyLoadImgs)
      //     cScroll.scroll(0, 0)
      //   }
      // })
    },
    tabActive(val) {
      if (!val) {
        clearInterval(this.getRoomsInverval)
      } else if (this.isDesktop) {
        this.getRooms()
        this.getRoomsInverval = setInterval(() => this.getRooms(), 30 * 1000)
      }
    }
  },
  computed: {
    ...mapState(['error', 'isDesktop', 'user', 'tabActive', 'playerPlay', 'theme']),
    inRoom() {
      return this.$route.name === 'smotrim-id'
    },
    changeRoute() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      themeColor: 'e00a1e',
      showPicker: false,
      lastupdate: Date.now(),
      rooms: null,
      roomsD: null,
      roomsPage: 1,
      showUserMenu: false,
      showEditName: false,
      newName: '',
      showMyRooms: false,
      onBlurTimeout: null,
      getRoomsInverval: null,
      roomsI: 1,
      showVideoAd: true,
      videoAdCounter: 15,
    }
  },
  mounted() {
    setInterval(() => {
      this.showVideoAd = true
    }, 5 * 60 * 1000)

    this.showAd()

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', () => { 
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    })

    // window.addEventListener('scroll', this.lazyLoadImgs)
    // this.lazyLoadImgs()
    
    // document.querySelector('.content-scroll').addEventListener('scroll', this.lazyLoadImgs)

    if (this.isDesktop) {
      this.getRooms()
      this.getRoomsInverval = setInterval(() => this.getRooms(), 30 * 1000)
    }

    // window.addEventListener('blur', this.onBlur)
    // window.addEventListener('focus', this.onFocus)

    const vm = this

    let hidden = "hidden";

    // Standards:
    if (hidden in document) {
      document.addEventListener("visibilitychange", onchange);
    } else if ((hidden = "mozHidden") in document) {
      document.addEventListener("mozvisibilitychange", onchange);
    } else if ((hidden = "webkitHidden") in document) {
      document.addEventListener("webkitvisibilitychange", onchange);
    } else if ((hidden = "msHidden") in document) {
      document.addEventListener("msvisibilitychange", onchange);
    } else if ("onfocusin" in document) {
      // IE 9 and lower:
      document.onfocusin = document.onfocusout = onchange;
    } else {
      // All others:
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
    }

    function onchange (evt) {
      const v = "visible"
      const h = "hidden"
      const evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h
      };

      evt = evt || window.event;

      if (evt.type in evtMap) {
        document.body.className = evtMap[evt.type];
        if (vm.isDesktop) {
          // if (!vm.playerPlay)
          vm.$store.commit('setTabActive', evtMap[evt.type] === 'hidden' ? 0 : 1)
        } else {
          vm.$store.commit('setTabActive', evtMap[evt.type] === 'hidden' ? 0 : 1)
        }
      } else {
        document.body.className = this[hidden] ? "hidden" : "visible";
        if (vm.isDesktop) {
          // if (!vm.playerPlay)
          vm.$store.commit('setTabActive', this[hidden] ? 0 : 1)
        } else {
          vm.$store.commit('setTabActive', this[hidden] ? 0 : 1)
        }
      }
      // console.log(vm.tabActive)
    }

    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[hidden] !== undefined) {
      onchange({ type: document[hidden] ? "blur" : "focus" });
    }
  },
  methods: {
    showAd() {
      if (!this.user || this.user.group === 1) {
        this.videoAdInterval = setInterval(() => {
          if (this.videoAdCounter > 0) {
            --this.videoAdCounter
          }
        }, 1000)
        
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaDesktopHorizontal = document.createElement('script')
          rsyaDesktopHorizontal.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-2003632-3',
      blockId: 'R-A-2003632-3'
    })
  })
    `
          document.body.appendChild(rsyaDesktopHorizontal)
          
          if (!this.isDesktop) {
    //         const rsyaMobileTop = document.createElement('script')
    //         rsyaMobileTop.innerHTML = `
    // window.yaContextCb.push(()=>{
    //   Ya.Context.AdvManager.render({
    //     renderTo: 'yandex_rtb_R-A-2003632-2',
    //     blockId: 'R-A-2003632-2'
    //   })
    // })
    //   `
    //         document.body.appendChild(rsyaMobileTop)

    //         const rsyaMobileFS = document.createElement('script')
    //         rsyaMobileFS.innerHTML = `
    // window.yaContextCb.push(()=>{
    //   Ya.Context.AdvManager.render({
    //     type: 'fullscreen',
    //     blockId: 'R-A-1740435-4'
    //   })
    // })`
    //         document.body.appendChild(rsyaMobileFS)

            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
    window.yaContextCb.push(()=>{
      Ya.Context.AdvManager.render({
        type: 'floorAd',
        blockId: 'R-A-2003632-1'
      })
    })`
            document.body.appendChild(rsyaFooterMobile)
          } else {

            const rsyaDesktopVideoAd = document.createElement('script')
            rsyaDesktopVideoAd.innerHTML = `
    window.yaContextCb.push(()=>{
      Ya.Context.AdvManager.render({
        renderTo: 'yandex_rtb_R-A-2003632-4',
        blockId: 'R-A-2003632-4'
      })
    })
      `
            document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 300)
      }
    },
    switchColor(lang) {
      document.body.dataset.lang = lang
    }, 
    async switchTheme(theme) {
      if (theme === this.theme) return
      const { data } = await this.$axios.post(`/api/user/theme`, { theme })
      // document.body.dataset.theme = data.theme

      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setTheme', data.theme)
      }
    }, 
    onBlur() {
      console.log('onBlur')
      
      this.onBlurTimeout = setTimeout(() => {
        if (!this.playerPlay) this.$store.commit('setTabActive', 0)
      }, 20 * 1000)
    },
    onFocus() {
      console.log('onFocus')

      clearTimeout(this.onBlurTimeout)

      this.$store.commit('setTabActive', 1)
    },
    myRooms() {
      if (!this.user) return
      this.showMyRooms = !this.showMyRooms
      if (this.showMyRooms) {
        const rooms = []
        this.roomsD.forEach(el => {
          if (el.owner && el.owner.id === this.user.id) {
            rooms.push(el)
          }
        })
        this.rooms.list = rooms
      } else {
        this.rooms.list = this.roomsD
      }
    },
    async changeName() {
      if (!this.user) return
      const badName = ['turk', 'bot', 'admin', 'administrator', 'turkkino', 'moderator', 'админ', 'бот', 'анимист', 'администратор']
      if (
        !this.newName.trim() ||
        badName.includes(this.newName.toLowerCase()) ||
        this.newName.match(/[^a-zA-Zа-яА-Я0-9 _].+/)
      ) {
        this.$store.commit('setError', { status: 'warn', msg: 'Неверный формат никнейма' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length < 3) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком короткое имя (мин. 3)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length > 30) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком длинное имя (макс. 30)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const { data } = await this.$axios.post('/api/user/nickname', { name: this.newName.trim() })
        if (data.error) {
          this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        } else {
          const user = this.user
          user.name = data.name
          this.$store.commit('setUser', user)
          this.newName = ''
          this.showEditName = false
          this.$store.commit('setError', { status: 'ok', msg: 'Никнейм успешно изменен' })
        }
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    avatarUploadTrigger() {
      this.$refs.avatarUpload.click()
    },
    uploadFile(event, typeImg) {
      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.gif' && imageSize < 1000) ||
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this

          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = async function() {
              let maxWidth, maxHeight, finalFile

              if (typeImg === 'avatar') {
                maxWidth = 120
                maxHeight = 120
              }

              if (typeImg === 'avatar' && imageExt === '.gif') {
                finalFile = reader.result
              } else {
                let imageWidth = image.width
                let imageHeight = image.height

                if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                  imageHeight *= maxWidth / imageWidth
                  imageWidth = maxWidth
                } else if (imageHeight > maxHeight) {
                  imageWidth *= maxHeight / imageHeight
                  imageHeight = maxHeight
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageWidth
                canvas.height = imageHeight

                const ctx = canvas.getContext('2d')
                ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

                finalFile = canvas.toDataURL(fileType)
              }

              // The resized file ready for upload
              const fileName = `${vm.user.id}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              vm.$store.commit('setError', { status: 'load', msg: 'Идет загрузка' })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              const { data } = await vm.$axios({
                method: 'post',
                url: `/upload?type=${typeImg}s`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              vm.$store.commit('setError', { status: 'ok', msg: 'Аватар установлен' })
              setTimeout(() => vm.$store.commit('clearError'), 1000)
              if (typeImg === 'avatar') {
                await vm.$axios.put('/api/user', { photo: data.url })
                const user = vm.user
                user.photo = data.url
                vm.$store.commit('setUser', user)
              }
            }
          }
        } else {
          this.$store.commit(
            'setError',
            'Файл не соответствует условиям: формат .gif, .jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    showLeftSide() {
      if (this.rooms) {
        this.rooms = null
      } else {
        this.getRooms()
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
    },
    roomsMore() {
      // this.lazyLoadImgs()

      const roomsMoreElem = document.querySelector('.rooms-list .items .rooms-more')
    
      if (roomsMoreElem) {
        const docHeight = document.documentElement.clientHeight
        const topPosition = docHeight - roomsMoreElem.getBoundingClientRect().top
        if (+topPosition) {
          ++this.roomsI
        }
      }
    },
    getRooms(page) {
      const body = {
        page,
        user_id: this.user ? this.user.id : null
      }
      this.$socket.emit(`turkkino:rooms:get`, body, (cb) => {
        this.roomsD = Object.assign(cb.list)
        this.rooms = cb
        this.$nextTick(() => {
          // this.lazyLoadImgs()
          // const roomsListElem = document.querySelector('.rooms-list .items')
          // if (roomsListElem) {
          //   roomsListElem.removeEventListener('scroll', this.roomsMore)
          //   roomsListElem.addEventListener('scroll', this.roomsMore)
          // }
        })
      })
    }
  }
}
</script>

<style>
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
      font-size: 16px;
    }
  }
</style>
